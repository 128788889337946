<template>
    <div>
      <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
          <div>
          <validation-observer ref="informationDocument">
              <b-row style="display:block;">
                  <b-col cols="6">
                  <b-form-group
                      label="Title"
                      label-for="h-Title"
                      label-cols-md="2"
                  >
                      <validation-provider
                          #default="{ errors }"
                          name="Title"
                          rules="required"
                      >
                          <b-form-input
                              id="h-Title"
                              type="text"
                              placeholder="Title"
                              maxlength="50"
                              v-model="title"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                  </b-col>
                  <b-col cols="6">
                  <b-form-group
                      label="Description"
                      label-for="h-Description"
                      label-cols-md="2"
                  >
                      <validation-provider
                          #default="{ errors }"
                          name="Description"
                          rules="required"
                      >
                          <b-form-textarea
                              id="h-Description"
                              no-resize
                              maxlength="500"
                              placeholder="Description"
                              v-model="discription"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                  </b-col>
              </b-row>
              <div class="sendBtn" style="text-align:left;">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="updateDocument"
                      :disabled="onlyRead || isSpinner"
                  >
                      Update
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="$router.push({name: 'application-note-mgnt'})"
                      :disabled="onlyRead || isSpinner"
                  >
                  Cancel
                  </b-button>
              </div>
          </validation-observer>
          </div>
    </div>
  </template>
  
<script>
import BCardCode from '@core/components/b-card-code'
import { required,min,max } from 'vee-validate/dist/rules'
import {
BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormRadio,BFormFile,BFormSelect,BSpinner,BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
extend('required',required);
extend('min',min);
extend('max',max);
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()
import { dbCollections } from "@/utils/firebaseCollection.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebaseTimeStamp from "firebase";
export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormRadio,
        BFormFile,
        BFormSelect,
        ValidationProvider,
        ValidationObserver,
        BSpinner,
        BFormTextarea
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            title:"",
            discription: "",
            isSpinner: false,
            onlyRead:false
        }
    },
    created() {
        if(this.$route.params.id) {
                this.isSpinner = true;
                db.collection(dbCollections.APPLICATIONNOTES).doc(this.$route.params.id).get().then((res)=>{
                        if(res.exists) {
                            this.title = res.data().title;
                            this.discription = res.data().discription;
                            this.isSpinner = false;
                        } else {
                                this.isSpinner = false;
                        }
                }).catch((err)=>{
                    this.isSpinner = false;
                    console.error(err)
                })
        }
        this.$root.$on('stopSpinner',()=>{
            this.isSpinner = false;
        });
        let userAccess = JSON.parse(localStorage.getItem('userAccess'));
        if(userAccess) {
            let index = userAccess.findIndex((ele)=>{
                return ele.name === "Application Note Management"
            })
            if(index !== -1) {
                if(userAccess[index].allow.toLowerCase() == 'read') {
                    this.onlyRead = true;
                }
            }
        }
    },
    methods: {
        updateDocument(){
            try {             
                var self = this;
                self.$refs.informationDocument.validate().then((valid) => {
                    if(self.$route.params.id) {
                        if (valid) {
                            this.isSpinner = true;
                            let obj = {
                                updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                                title: self.title,
                                discription: self.discription,
                            }
                            db.collection(dbCollections.APPLICATIONNOTES).doc(self.$route.params.id).update(obj).then(()=>{
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: 'Application Note updated successfully',
                                        icon: 'CoffeeIcon',
                                        variant: 'success',                               
                                    },
                                })
                                this.$router.push({name: 'application-note-mgnt'});
                                this.isSpinner = false;
                            }).catch((err)=>{
                                console.error(err)
                            })
                        } else {
                            this.isSpinner = false;
                        }
                    }
                });
            } catch (error) {
                this.isSpinner = false;
                console.error(error);
            }
        },
    },
};
</script>

<style>

</style>